import {createSvgIcon, SvgIcon} from "@mui/material";
import {ReactComponent as ChatSmileOutlined} from "./res/icons/chat_smile.svg";
import {ReactComponent as HomeSmileOutlined} from "./res/icons/home_smile.svg";
import {ReactComponent as WikiOutlined} from "./res/icons/wiki.svg";
import {ReactComponent as TownhallOutlined} from "./res/icons/townhall.svg";
import {ReactComponent as EraserOutlined} from "./res/icons/eraser.svg";
import {ReactComponent as CommentUserOutlined} from "./res/icons/comment_user.svg";
import {ReactComponent as CommentQuoteOutlined} from "./res/icons/comment_quote.svg";

import {
  AdminPanelSettingsOutlined,
  AppsOutlined,
  ArticleOutlined,
  AssignmentOutlined,
  BusinessOutlined,
  BallotOutlined,
  BoltOutlined,
  BookmarksOutlined,
  BubbleChartOutlined,
  BusinessCenterOutlined,
  CalendarMonthOutlined,
  CallOutlined,
  CategoryOutlined,
  ChangeCircleOutlined,
  ChatBubbleOutlineOutlined,
  ChatOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ContactsOutlined,
  ContentPasteSearchOutlined,
  DynamicFormOutlined,
  EditOutlined,
  ExtensionOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FormatOverlineOutlined,
  GamepadOutlined,
  GroupsOutlined,
  HandymanOutlined,
  HelpOutlineOutlined,
  HistoryEduOutlined,
  HomeOutlined,
  ImageOutlined,
  ImageSearchOutlined,
  KitchenOutlined,
  LayersOutlined,
  LockOutlined,
  LooksOneOutlined,
  MailOutlineOutlined,
  PublicOutlined,
  RocketLaunchOutlined,
  SearchOutlined,
  SettingsOutlined,
  ShapeLineOutlined,
  StarOutlined,
  StickyNote2Outlined,
  TaskAltOutlined,
  TimelineOutlined,
  ViewComfyOutlined,
  ViewInArOutlined,
  WebAssetOutlined,
  HandshakeOutlined, TerminalOutlined, SpaceDashboardOutlined, AccountCircleOutlined
} from "@mui/icons-material";
import {ReactNode} from "react";

export class Icon {
  constructor(readonly name: string, readonly iconType: typeof SvgIcon) {
  }
}

export const ICONS: Icon[] = [
  new Icon("admin_panel_settings", AdminPanelSettingsOutlined),
  new Icon("apps", AppsOutlined),
  new Icon("account_circle", AccountCircleOutlined),
  new Icon("assets", WebAssetOutlined),
  new Icon("assignment", AssignmentOutlined),
  new Icon("article", ArticleOutlined),
  new Icon("ballot", BallotOutlined),
  new Icon("bolt", BoltOutlined),
  new Icon("bookmarks", BookmarksOutlined),
  new Icon("business", BusinessOutlined),
  new Icon("business_center", BusinessCenterOutlined),
  new Icon("category", CategoryOutlined),
  new Icon("bubble_chart", BubbleChartOutlined),
  new Icon("cabinet", KitchenOutlined),
  new Icon("calendar", CalendarMonthOutlined),
  new Icon("call", CallOutlined),
  new Icon("change_circle", ChangeCircleOutlined),
  new Icon("check", CheckOutlined),
  new Icon("check_circle", CheckCircleOutlined),
  new Icon("chat_bubble", ChatBubbleOutlineOutlined),
  new Icon("chat", ChatOutlined),
  new Icon("contacts", ContactsOutlined),
  new Icon("content_search", ContentPasteSearchOutlined),
  new Icon("dynamic_form", DynamicFormOutlined),
  new Icon("edit", EditOutlined),
  new Icon("extension", ExtensionOutlined),
  new Icon("folder", FolderOutlined),
  new Icon("folder_open", FolderOpenOutlined),
  new Icon("format_overline", FormatOverlineOutlined),
  new Icon("gamepad", GamepadOutlined),
  new Icon("groups", GroupsOutlined),
  new Icon("home", HomeOutlined),
  new Icon("image", ImageOutlined),
  new Icon("image_search", ImageSearchOutlined),
  new Icon("layers", LayersOutlined),
  new Icon("lock", LockOutlined),
  new Icon("mail", MailOutlineOutlined),
  new Icon("one", LooksOneOutlined),
  new Icon("public", PublicOutlined),
  new Icon("rocket", RocketLaunchOutlined),
  new Icon("scribe", HistoryEduOutlined),
  new Icon("search", SearchOutlined),
  new Icon("settings", SettingsOutlined),
  new Icon("shape_line", ShapeLineOutlined),
  new Icon("star", StarOutlined),
  new Icon("sticky_note", StickyNote2Outlined),
  new Icon("task", TaskAltOutlined),
  new Icon("timeline", TimelineOutlined),
  new Icon("tools", HandymanOutlined),
  new Icon("users", GroupsOutlined),
  new Icon("view_in_ar", ViewInArOutlined),
  new Icon("view_comfy", ViewComfyOutlined),
  new Icon("handshake", HandshakeOutlined),
  new Icon("terminal", TerminalOutlined),
  new Icon("space_dashboard", SpaceDashboardOutlined),
  new Icon("chat_smile", createIcon(<ChatSmileOutlined/>)),
  new Icon("home_smile", createIcon(<HomeSmileOutlined/>)),
  new Icon("wiki", createIcon(<WikiOutlined/>)),
  new Icon("townhall", createIcon(<TownhallOutlined/>)),
  new Icon("eraser", createIcon(<EraserOutlined/>)),
  new Icon("comment_user", createIcon(<CommentUserOutlined/>)),
  new Icon("comment_quote", createIcon(<CommentQuoteOutlined/>)),
];

export function findIcon(name: string): typeof SvgIcon {
  let icon = ICONS.find(icon => icon.name == name);
  return icon?.iconType || HelpOutlineOutlined;
}

export function createIcon(path: ReactNode) {
  return createSvgIcon(path, "");
}