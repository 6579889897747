import React from "react";
import {StyledContainer} from "../../shared/StyledComponents";
import {getAuth} from "@firebase/auth";
import {Game} from "../types";
import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {PD_LG} from "../../shared/dimens";

export type GameFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  game: Game,
}

export type GameFragmentState = FullscreenDialogWithTitleFragmentState & {
}

export class GameFragment extends FullscreenDialogWithTitleFragment<GameFragmentProps, GameFragmentState> {

  protected readonly auth = getAuth();

  getTitle(): string {
    return this.props.game.name;
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size={"xlg"} style={{margin: PD_LG, alignItems: "center"}}>
      <iframe src={"https://scratch.mit.edu/projects/" + this.props.game.scratchProjectId + "/embed"} width="100%"
              height="640px"
              frameBorder="0" scrolling="no" allowFullScreen></iframe>
    </StyledContainer>;
  }
}