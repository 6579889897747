import React, {PureComponent} from "react";
import {Box, Card, Typography} from "@mui/material";
import {StyledBoxColumn} from "../shared/StyledComponents";
import {DIVIDER, PD_LG, PD_XLG} from "../shared/dimens";

import {Game} from "./types";
import {COLOR_PRIMARY} from "./App";
import {lightGray} from "../shared/colors";

export type GameCardProps = {
  style?: any,
  game: Game,
  children?: any,
  onClick: () => void,
}

export class GameCard extends PureComponent<GameCardProps> {

  render() {
    const game = this.props.game;
    return <Card style={{borderRadius: 12, cursor: "pointer", ...this.props.style}} onClick={() => this.props.onClick()}>
      <Box style={{display: "flex", flexDirection: "column", width: "100%", position: "relative"}}>
        <StyledBoxColumn style={{background: "white", alignItems: "center", gap: PD_XLG, aspectRatio: 1}}>
          <img src={game.icon} style={{width: "100%", aspectRatio: 1, objectFit: "contain"}}/>
        </StyledBoxColumn>
        <Typography style={{borderTop: DIVIDER, fontFamily: "Gabarito, sans-serif", fontSize: "125%", fontWeight: "bold", textAlign:"center", padding: PD_LG}}>{game.name}</Typography>
      </Box>
    </Card>
  }
}