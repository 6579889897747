import {BaseApp, BaseAppProps, BaseAppState, ContextType} from "./BaseApp";
import {ProvisionedAppConfig, ProvisionedAppContext} from "./types";
import {JSON_OBJECT} from "./json/helpers";

export abstract class ProvisionedApp<P extends BaseAppProps = BaseAppProps, S extends BaseAppState = BaseAppState> extends BaseApp<P, S> implements ProvisionedAppContext {

  static CONTEXT: ProvisionedAppContext;

  constructor(props: P, context: any) {
    super(props, context);
    ProvisionedApp.CONTEXT = this;
  }

  contextType(): ContextType {
    return ContextType.PROVISIONED_APP;
  }

  protected onConfigureApp() {
    super.onConfigureApp();
    const provisionedAppConfig = this.getProvisionedAppConfig();
    if (provisionedAppConfig) {
      this.applyProvisionedAppConfig(provisionedAppConfig);
    }
  }

  abstract getProvisionedAppId(): string;

  abstract getProvisionedAppConfig(): ProvisionedAppConfig;
}
