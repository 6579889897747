import React, {ReactElement} from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Slide,
  Snackbar,
  Theme,
  ThemeProvider,
  Typography,
  Zoom
} from "@mui/material";
import {getAuth, signInWithEmailAndPassword, User} from "@firebase/auth";
import {FirebaseApp, initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {FirebaseConfig, Login, LoginConfig} from "./Login";
import {
  BORDER_RADIUS,
  DIVIDER,
  DW_LG,
  DW_SM,
  PD_MD,
  PD_SM,
  PD_XSM,
  PD_XXLG,
  SZ_HEADER,
  SZ_SM,
  SZ_SMSM,
  SZ_XXLG
} from "./dimens";
import {black, colorRed, lightGray, white} from "./colors";
import {Close} from "@mui/icons-material";
import {
  $KTS,
  Action,
  ActionBase,
  ActionType,
  AppletConfig,
  AppOverlay,
  AppPrefListener,
  LoginCredentials,
  MenuOption,
  PluginConfig,
  PluginIconUrl,
  PluginManifest,
  ProvisionedAppConfig, ProvisioningConfig,
  UserCache
} from "./types";
import {PathProps} from "../index";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {ActionsDialogContent, CustomDialogContent, TextDialogContent, TextInputDialogContent} from "./Dialogs";
import {Prefs} from "./prefs";
import {initMemberAuth} from "./auth";
import {findIcon} from "./icons";
import {StringUtil} from "./string_util";
import {
  StyledBoxColumn,
  StyledBoxColumnCard,
  StyledBoxRow,
  StyledHorizontalDivider,
  StyledSpan
} from "./StyledComponents";
import {PluginStoreConfig, SystemPluginsConfig} from "./plugins";
import Draggable from 'react-draggable';
import {SystemProvisioningIds} from "./database";
import "./res/css/Shared.css";
import {BaseAppPrefs} from "./BaseAppPrefs";
import {App} from "../app/App";
import {FilesConfig} from "./files";
import {TransitionProps} from '@mui/material/transitions';
import {md5_uuid} from "./md5";

export type MenuProps = {
  id: string,
  args?: any[],
}

export type DialogProps = {
  id?: string,
  flags?: number,
  args?: any[],
}

type ShowDialog = {
  id: string,
  flags: number,
  rendered: ReactElement,
  onClose?: () => void,
}

export type PopoverProps = {
  id?: string,
  flags?: number,
  args?: any[],
  anchorOrigin?: any,
  transformOrigin?: any,
}

type ShowPopover = {
  anchorEl: HTMLElement,
  anchorOrigin?: any,
  transformOrigin?: any,
  rendered: ReactElement,
  onClose?: () => void,
}

type ShowProgressDialog = {
  title: string,
  progress: number,
}

type ShowMenu = {
  anchorEl: any,
  options: MenuOption[],
  onMenuButtonClicked?: (menuOption: MenuOption) => void,
}

type ShowToast = {
  text: string,
  action?: Action,
  onClose?: () => void,
}

export const DIALOG_FLAG_DISABLE_BACKDROP_CLICK = 1 << 0;
export const DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN = 1 << 1;
export const DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE = 1 << 2;
export const DIALOG_FLAG_SHOW_DIALOG_OVERLAY = 1 << 3;
export const DIALOG_FLAG_SHOW_CLOSE = 1 << 4;
export const DIALOG_FLAG_ANIM_SLIDE = 1 << 5;

export type BaseAppProps = {
  path: PathProps,
};

export type BaseAppState = {
  user?: User | null,
  ready?: boolean,
  autoLoggingIn?: boolean,
  hasPhonecall?: boolean,
  phonecallFragmentMinimized?: boolean,
  showToast?: ShowToast | null,
  showFloatingView?: ReactElement,
  showDialogs: ShowDialog[],
  showProgressDialog?: ShowProgressDialog,
  showPopover?: ShowPopover,
  showMenu?: ShowMenu,
  fullscreenElement?: ReactElement | null,
}

export type SafeAreaInsets = {
  top?: number,
  left?: number,
  bottom?: number,
  right?: number,
}

export type StyleOverrides = {
  tabButton?: any,
}

export type KeyboardShortcut = {
  text: string,
  kbd: string[],
  sep?: string,
}

export type KeyboardShortcutSection = {
  title: string,
  shortcuts: KeyboardShortcut[],
}

export type KeyboardShortcuts = {
  sections: KeyboardShortcutSection[],
}

export type KeyboardConfig = {
  shortcuts?: KeyboardShortcuts,
}

// TODO: Fill this out when search is supported.
export type SearchConfig = {}

export type AppConfig = {
  name: string,
  version?: string,
  icon: string,
  logo: string,
  stamp: string,
  stampHeight?: number,
  stampText?: string,
  theme?: Theme,
  privacyUrl?: string,
  termsUrl?: string,
  safeAreaInsets?: SafeAreaInsets,
  styleOverrides?: StyleOverrides,
  defaultUserImage?: string,
  firebaseConfig?: FirebaseConfig,
  loginConfig?: LoginConfig,
  systemPluginsConfig?: SystemPluginsConfig,
  pluginStoreConfig?: PluginStoreConfig,
  filesConfig?: FilesConfig,
  keyboardConfig?: KeyboardConfig,
  searchConfig?: SearchConfig,
}

export function showKeyboardShortcutsDialog(shortcuts: KeyboardShortcuts): void {
  const sections = shortcuts?.sections;
  if (!(sections?.length > 0)) {
    return;
  }
  BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE},
    () => <CustomDialogContent
      style={{width: DW_LG}}
      title={"Keyboard shortcuts"}
      customView={<Grid container spacing={2}>
        {sections.map(section => <Grid item xs={12} sm={6}>
          <StyledBoxColumnCard title={section.title}>
            <Box style={{display: "flex", flexDirection: "column"}}>
              {section.shortcuts?.map((shortcut, index) => <StyledBoxRow
                style={{alignItems: "center", padding: PD_SM, borderTop: index > 0 ? DIVIDER : null,}}>
                <Typography>{shortcut.text}</Typography>
                <StyledSpan/>
                {shortcut.kbd?.map((kbd, index) => (<><span>{index > 0 ? (shortcut.sep || "+") : null}</span><kbd>{kbd}</kbd></>))}
              </StyledBoxRow>)}
            </Box>
          </StyledBoxColumnCard>
        </Grid>)}
      </Grid>}/>
  );
}

export function renderStamp(height?: number): ReactElement {
  const appConfig = BaseApp.CONTEXT.getAppConfig();
  const theme = appConfig.theme;
  const stamp = appConfig.stamp || appConfig.icon || "@icon/extension";
  if (stamp?.endsWith(".png")) {
    return <StyledBoxColumn style={{alignItems: "center", gap: PD_MD}}>
      <img src={stamp}
           style={{height: height || appConfig.stampHeight || 72, alignSelf: "center", borderRadius: BORDER_RADIUS}}/>
      {appConfig.stampText
        ? <Typography variant="h5">{appConfig.stampText}</Typography>
        : null}
    </StyledBoxColumn>;
  } else if (stamp?.startsWith("@icon/")) {
    const IconType = findIcon(stamp.substring("@icon/".length));
    return <StyledBoxColumn style={{alignItems: "center", gap: PD_MD}}>
      <IconType style={{
        width: 144,
        height: 144,
        alignSelf: "center",
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.primary.main,
        color: "white"
      }} viewBox="-6 -6 36 36"/>
      {appConfig.stampText
        ? <Typography variant="h5">{appConfig.stampText}</Typography>
        : null}
    </StyledBoxColumn>
  }
  return <Typography variant="h3" style={{alignSelf: "center"}}>{stamp}</Typography>;
}

export function renderLogo() {
  const theme = BaseApp.CONTEXT.getAppConfig().theme;
  const logo = BaseApp.CONTEXT.getAppConfig().logo || BaseApp.CONTEXT.getAppConfig().icon || "@icon/extension";
  if (logo?.endsWith(".png")) {
    return <img src={logo} style={{height: 128, borderRadius: theme.shape.borderRadius}}/>;
  } else if (logo?.startsWith("@icon/")) {
    const IconType = findIcon(logo.substring("@icon/".length));
    return <IconType style={{
      width: 192,
      height: 192,
      alignSelf: "center",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }} viewBox="-6 -6 36 36"/>
  }
  return <Typography variant="h3" style={{alignSelf: "center"}}>{logo}</Typography>;
}

export function renderPluginIcon(pluginManifest: PluginManifest) {
  if (pluginManifest.iconUrl) {
    return <img src={PluginIconUrl(pluginManifest)} style={{width: "100%", height: "100%"}}/>;
  } else if (pluginManifest.iconType) {
    const theme = BaseApp.CONTEXT.getAppConfig().theme;
    const IconType = findIcon(pluginManifest.iconType);
    return <IconType style={{
      width: "100%",
      height: "100%",
      alignSelf: "center",
      borderRadius: BORDER_RADIUS,
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }} viewBox="-6 -6 36 36"/>
  }
  return null;
}

function Splash() {
  return <Box style={{
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <Zoom in timeout={100} unmountOnExit>{renderLogo()}</Zoom>
  </Box>;
}

export enum ContextType {
  APP = "app",
  PROVISIONED_APP = "provisioned_app",
  PLUGIN = "plugin",
  APPLET = "applet",
}

export interface Context {

  isInhouse(): boolean;

  contextType(): ContextType;

  getAppConfig(): AppConfig;

  getAppPrefs<T extends BaseAppPrefs>(): T;

  notifyAppEvent(eventName: string, ...args: any[]);

  addEventListener(eventName: string, listener: AppEventListener);

  removeEventListener(eventName: string, listener: AppEventListener);

  showAlert(title: string, text: string, actions?: Action[], onClose?: () => void): void;

  showFullscreenDialog(render: (props: DialogProps) => ReactElement, onClose?: () => void): string;

  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): string;

  hideDialog(id?: string): void;

  hideAllDialogs(): void;

  showActions(anchorEl: HTMLElement, props: PopoverProps, actions: Action[], onClose?: () => void, ...args: any[]): void;

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void;

  showActionsListPopover(anchorEl: HTMLElement, props: PopoverProps, actions: ActionBase[], onClose?: () => void): void;

  hidePopover(): void;

  showErrorDialog(text: string),

  showCustomDialog(title: string, customView: ReactElement, action?: Action, altAction?: Action, cancelable?: boolean),

  showTextDialog(title: string, text: string, action: Action, altAction?: Action, cancelable?: boolean, style?: any),

  showActionsDialog(title: string, actions: Action[], ...args: any[]),

  showTextInputDialog(title: string, text: string, textLabel: string, action: Action, cancelable?: boolean, initialValue?: string);

  showProgressDialog(title: string, progress?: number);

  updateProgressDialog(progress: number);

  showMenu(anchorEl: HTMLElement, props: MenuProps, create: (props: MenuProps) => MenuOption[], onMenuButtonClicked: (option: MenuOption) => void);

  hidePopover();

  showToast(text: string): void;

  showFloatingView(render: (...args: any[]) => ReactElement);

  hideFloatingView();

  getFullscreenElement(): ReactElement | null;

  setFullscreenElement(fragment: ReactElement | null);

  hasPhonecall(): boolean;

  maximizePhonecallFragment(): void;

  doAutoLogin(autoLogin: LoginCredentials): Promise<void>;
}

const PROVISIONING_ID_PREF = new Prefs();

export function getProvisioningId(): string {
  return PROVISIONING_ID_PREF.getString(BaseApp.CONTEXT.getAppConfig()?.name + "-provisioning_id") || SystemProvisioningIds.MAIN;
}

export function setProvisioningId(provisioningId: string) {
  PROVISIONING_ID_PREF.setString(BaseApp.CONTEXT.getAppConfig()?.name + "-provisioning_id", provisioningId);
}

export function clearProvisioningId() {
  PROVISIONING_ID_PREF.remove(BaseApp.CONTEXT.getAppConfig()?.name + "-provisioning_id");
}

const LOGIN_CREDENTIALS_PREF = new Prefs();

export function getLoginCredentials(): LoginCredentials {
  const string = LOGIN_CREDENTIALS_PREF.getString(BaseApp.CONTEXT.getAppConfig()?.name + "-lc");
  try {
    return string ? JSON.parse(atob(string)) as LoginCredentials : null;
  } catch (e) {
    return null;
  }
}

export function setLoginCredentials(loginCredentials: LoginCredentials) {
  LOGIN_CREDENTIALS_PREF.setString(BaseApp.CONTEXT.getAppConfig()?.name + "-lc", btoa(JSON.stringify(loginCredentials)));
}

export function clearLoginCredentials() {
  LOGIN_CREDENTIALS_PREF.remove(BaseApp.CONTEXT.getAppConfig()?.name + "-lc");
}

export interface AppEventListener {

  onEvent(eventName: string, ...args: any[]);
}

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export abstract class BaseApp<P extends BaseAppProps = BaseAppProps, S extends BaseAppState = BaseAppState> extends React.Component<P, S> implements Context, AppPrefListener {

  static CONTEXT: Context;

  protected readonly auth;
  protected readonly analytics;

  private appInit?: boolean;
  private appConfig: AppConfig;
  private autoLogin?: LoginCredentials;

  private readonly eventListeners = new Map<string, AppEventListener[]>();

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
    BaseApp.CONTEXT = this;
    this.onConfigureApp();
    const firebaseConfig = this.getAppConfig().firebaseConfig;
    if (firebaseConfig) {
      const app = initializeApp(firebaseConfig.options);
      this.auth = this.getAuth(app);
      this.analytics = getAnalytics(app);
    }
  }

  protected getAuth(app: FirebaseApp) {
    return getAuth();
  }

  isInhouse(): boolean {
    return getProvisioningId() === SystemProvisioningIds.INHOUSE;
  }

  contextType(): ContextType {
    return ContextType.APP;
  }

  getAppPrefs<T extends BaseAppPrefs>(): T {
    return null;
  }

  protected onCreateState() {
    return {
      showDialogs: [],
    } as S;
  }

  protected onConfigureApp() {
  }

  addEventListener(eventName: string, listener: AppEventListener) {
    let listeners = this.eventListeners.get(eventName);
    if (!listeners) {
      listeners = [];
      this.eventListeners.set(eventName, listeners);
    }
    listeners.push(listener);
  }

  removeEventListener(eventName: string, listener: AppEventListener) {
    const listeners = this.eventListeners.get(eventName);
    const index = listeners?.findIndex(l => l === listener);
    if (index >= 0) {
      listeners.splice(index, 1);
    }
  }

  notifyAppEvent(eventName: string, ...args: any[]) {
    this.eventListeners.get(eventName)?.forEach(listener => listener.onEvent(eventName, ...args));
  }

  onPrefChanged(key: string) {
    // switch (key) {
    //   case <app-wide key>:
    //     this.notifyAppEvent(<app-wide-key-name>);
    //     break;
    // }
  }

  applyProvisionedAppConfig(config: ProvisionedAppConfig) {
    if (!config) {
      return;
    }
    if (config.provisioningId) {
      setProvisioningId(config.provisioningId);
    }
  }

  applyPluginConfig(config: PluginConfig) {
    if (!config) {
      return;
    }
    if (config.app) {
      this.applyAppOverlay(config.app);
    }
    // If this app doesn't want to login (e.g. running as a noLogin PluginApp), we don't want to autologin in that case.
    if (!this.getAppConfig().loginConfig?.noLogin && config.autoLogin) {
      this.autoLogin = config.autoLogin;
    }
  }

  applyAppletConfig(config: AppletConfig) {
    if (!config) {
      return;
    }
    if (config.autoLogin) {
      this.autoLogin = config.autoLogin;
    }
  }

  private applyAppOverlay(overlay: AppOverlay) {
    const appConfig = this.getAppConfig();
    if (overlay.name) {
      appConfig.name = overlay.name || appConfig.name;
    }
    if (overlay.stamp) {
      appConfig.stamp = overlay.stamp;
    }
    if (overlay.themeOptions) {
      appConfig.theme = createTheme(overlay.themeOptions);
    }
    // If this app doesn't want to login or has noOverride set (when running as a noLogin PluginApp), we don't want to
    // apply firebase config, which allows us to embed plugin apps from a different project.
    if (!this.getAppConfig().loginConfig?.noLogin && !this.getAppConfig().firebaseConfig?.noOverride && overlay.firebase) {
      appConfig.firebaseConfig = {
        options: overlay.firebase,
      };
    }
  }

  getAppConfig(): AppConfig {
    if (!this.appConfig) {
      this.appConfig = this.onCreateAppConfig();
    }
    return this.appConfig;
  }

  abstract onCreateAppConfig(): AppConfig;

  componentDidMount() {
    // this.getAppPrefs()?.addListener(<app-wide-key>, this);
    this.onAppMounted();
  }

  componentWillUnmount() {
    // this.getAppPrefs()?.removeListener(<app-wide-key></app-wide-key>, this);
  }

  private async onAppMounted() {
    if (!await this.checkBrowserState()) {
      this.showDialog({flags: DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () => this.renderAlertDialog(
        "Third party cookies disabled",
        StringUtil.format("It looks like you have disabled third-party cookies in your browser." +
          " This also disables the LocalStorage API used by %{app_name}." +
          " Please re-enable third-party cookies and try again.", $KTS("app_name", this.appConfig.name)),
        [
          new Action("Try again", () => {
            window.location.reload();
          }),
        ]));
      return;
    }
    if (this.autoLogin) {
      await this.doAutoLogin(this.autoLogin);
    }
    await this.checkAuthState();
  }

  async doAutoLogin(autoLogin: LoginCredentials): Promise<void> {
    this.setState({
      autoLoggingIn: true,
    });
    await signInWithEmailAndPassword(this.auth, autoLogin.email, autoLogin.password)
      .then(() => setLoginCredentials(autoLogin));
    this.setState({
      autoLoggingIn: false,
    });
  }

  private async checkBrowserState(): Promise<boolean> {
    if (!window.localStorage) {
      return false;
    }
    return true;
  }

  private async checkAuthState() {
    // await sleep(1000);
    this.auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        if (this.appInit) {
          return;
        }
        this.appInit = true;
        this.onAppInitWithLoggedInUser(user)
          .then(() => {
            UserCache.getInstance().getUser(user.uid)
              .then(() => this.setState({
                user: user,
                ready: true,
              }));
          });
      } else {
        this.appInit = false;
        this.setState({
          user: null,
          ready: true,
        });
      }
    });
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await initMemberAuth();
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
  }

  showFloatingView(render: () => React.ReactElement) {
    this.setState({
      showFloatingView: render(),
    });
  }

  hideFloatingView() {
    this.setState({
      showFloatingView: null,
    });
  }

  getFullscreenElement(): ReactElement | null {
    return this.state.fullscreenElement;
  }

  setFullscreenElement(element: ReactElement | null) {
    this.setState({
      fullscreenElement: element,
    });
  }

  showAlert(title: string, text: string, actions?: Action[], onClose?: () => void): void {
    this.showDialog(null, () => this.renderAlertDialog(title, text, actions));
  }

  private renderAlertDialog(title: string, text: string, actions?: Action[]): ReactElement {
    if (!(actions?.length > 0)) {
      actions = [
        new Action("OK"),
      ];
    }
    return <Box
      style={{maxWidth: DW_SM, display: "flex", flexDirection: "column", padding: PD_MD, gap: PD_MD}}>
      <Typography variant="h6">{title}</Typography>
      <Typography>{text}</Typography>
      <Box style={{display: "flex", flexDirection: "row-reverse", gap: PD_SM}}>
        {actions.map(action => <Button
          variant="text"
          color={action.destructive ? "error" : "primary"}
          style={{textTransform: "uppercase"}}
          onClick={(event) => {
            this.hideDialog();
            setTimeout(() => action.onClick?.(event), 50);
          }}>{action.text}</Button>)}
      </Box>
    </Box>;
  }

  showFullscreenDialog(render: (props: DialogProps) => React.ReactElement, onClose?: () => void): string {
    return this.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, render, onClose);
  }

  showDialog(props: DialogProps, render: (props: DialogProps) => ReactElement, onClose?: () => void): string {
    if (!onClose) {
      onClose = () => BaseApp.CONTEXT.hideDialog();
    }
    let flags = props?.flags || 0;
    const id = md5_uuid();
    this.setState({
      showDialogs: [{
        id: id,
        flags: flags,
        rendered: render(props),
        onClose: onClose,
      } as ShowDialog,
        ...this.state.showDialogs]
    });
    return id;
  }

  hideDialog(id?: string): void {
    if (id) {
      const index = this.state.showDialogs.findIndex(showDialog => showDialog.id === id);
      if (index >= 0) {
        this.setState({
          showDialogs: [...this.state.showDialogs.slice(0, index), ...this.state.showDialogs.slice(index + 1)],
        });
      }
    } else {
      this.setState({
        showDialogs: this.state.showDialogs.slice(1),
      });
    }
  }

  hideAllDialogs(): void {
    this.setState({
      showDialogs: [],
    });
  }

  showErrorDialog(text: string) {
    this.showDialog(null, () => <TextDialogContent title="Error" text={text}
                                                   action={new Action("OK", () => this.hideDialog())}/>);
  }

  showCustomDialog(title: string, customView: ReactElement, action?: Action, altAction?: Action, cancelable?: boolean) {
    this.showDialog(null, () => <CustomDialogContent title={title} customView={customView} action={action}
                                                     altAction={altAction} cancelable={cancelable}/>);
  }

  showTextDialog(title: string, text: string, action: Action, altAction?: Action, cancelable?: boolean, style?: any) {
    this.showDialog(null, () => <TextDialogContent title={title} text={text} action={action} altAction={altAction}
                                                   cancelable={cancelable} style={style}/>);
  }

  showActionsDialog(title: string, actions: Action[], ...args: any[]) {
    this.showDialog(null, () => <ActionsDialogContent title={title} actions={actions} args={args}/>);
  }

  showTextInputDialog(title: string, text: string, textLabel: string, action: Action, cancelable?: boolean, initialValue?: string) {
    this.showDialog(null, () => <TextInputDialogContent title={title} text={text} textLabel={textLabel}
                                                        action={action}/>);
  }

  showProgressDialog(title: string, progress?: number) {
    let percent = this.getProgressPercent(progress);
    this.setState({
      showProgressDialog: {
        title: title,
        progress: percent,
      } as ShowProgressDialog,
    });
  }

  private getProgressPercent(progress: number): number {
    return Math.floor(Math.max(0, Math.min(100, progress || 0)));
  }

  updateProgressDialog(progress: number) {
    if (!this.state.showProgressDialog) {
      return;
    }
    let percent = Math.floor(Math.max(0, Math.min(100, progress || 0)));
    this.setState({
      showProgressDialog: percent >= 100 ? null : {
        title: this.state.showProgressDialog.title,
        progress: percent,
      } as ShowProgressDialog,
    });
  }

  showActions(anchorEl: HTMLElement, props: PopoverProps, actions: Action[], onClose?: () => void, ...args: any[]): void {
    this.showPopover(
      anchorEl,
      props,
      () => <List style={{padding: 0}}>
        {actions.map(action => {
            const IconType = action.iconType;
            return <ListItem style={{padding: 0}}>
              <ListItemButton
                onClick={(event) => {
                  BaseApp.CONTEXT.hidePopover();
                  action.onClick(event, ...args);
                }}>
                {IconType ? <ListItemIcon>
                    <IconType/>
                  </ListItemIcon>
                  : null}
                <Typography style={{marginLeft: Boolean(IconType) ? -16 : 0}}>
                  {action.text}
                </Typography>
              </ListItemButton>
            </ListItem>;
          }
        )}
      </List>,
      onClose);
  }

  showPopover(anchorEl: HTMLElement, props: PopoverProps, render: (props: PopoverProps) => ReactElement, onClose?: () => void): void {
    this.setState({
      showPopover: {
        anchorEl: anchorEl,
        anchorOrigin: props?.anchorOrigin,
        transformOrigin: props?.transformOrigin,
        rendered: render(props),
        onClose: onClose,
      } as ShowPopover,
    });
  }

  showActionsListPopover(anchorEl: HTMLElement, props: PopoverProps, actions: ActionBase[], onClose?: () => void) {
    this.showPopover(anchorEl, props, () => {
        return <List style={{minWidth: SZ_XXLG}}>
          {actions.map(action => {
            switch (action.type) {
              case ActionType.SEPARATOR:
                return <StyledHorizontalDivider/>;
              case ActionType.BUTTON:
                const button = action as Action;
                return <ListItemButton
                  onClick={(event) => {
                    App.CONTEXT.hidePopover();
                    button.onClick?.(event);
                  }}>
                  {button.iconType
                    ? <ListItemIcon
                      style={{color: button.destructive ? colorRed : null}}>
                      {<button.iconType/>}
                    </ListItemIcon>
                    : null}
                  <ListItemText
                    style={{color: button.destructive ? colorRed : null}}>
                    {button.text}
                  </ListItemText>
                  {button.keyboardShortcut
                    ? <Typography sx={{color: "text.secondary"}}>
                      {button.keyboardShortcut}
                    </Typography>
                    : null}
                </ListItemButton>;
            }
            return null;
          })}
        </List>;
      },
      onClose);
  }

  hidePopover() {
    this.setState({
      showPopover: null,
    });
  }

  showMenu(anchorEl: HTMLElement, props: MenuProps, create: (props: MenuProps) => MenuOption[], onMenuButtonClicked: (option: MenuOption) => void) {
    this.setState({
      showMenu: {
        anchorEl: anchorEl,
        options: create(props),
        onMenuButtonClicked: onMenuButtonClicked,
      } as ShowMenu,
    });
  }

  showToast(text: string, action?: Action, onClose?: () => void): void {
    this.setState({
      showToast: {
        text: text,
        action: action,
        onClose: onClose,
      }
    });
  }

  hasPhonecall(): boolean {
    return false;
  }

  maximizePhonecallFragment(): void {
  }

  protected hasAuthCredentials(): boolean {
    return Boolean(getLoginCredentials()) && Boolean(this.auth.currentUser);
  }

  render() {
    let rendered;
    if (this.state.ready) {
      if (this.getAppConfig()?.loginConfig?.noLogin || this.hasAuthCredentials()) {
        rendered = this.renderMain();
      } else {
        rendered = this.renderLogin();
      }
    } else {
      if (this.state.autoLoggingIn) {
        rendered = this.renderPleaseWait();
      } else {
        rendered = <Splash/>;
      }
    }
    return <ThemeProvider theme={this.getAppConfig().theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}>
          {Boolean(this.state.showFloatingView) ?
            this.renderFloatingView()
            : null}
          {Boolean(this.state.showToast) ?
            this.renderToast()
            : null}
          {[...this.state.showDialogs].reverse().map((showDialog, index) => (showDialog.flags & DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN) !== 0 ?
            this.renderFullscreenDialog(showDialog, index)
            : this.renderDialog(showDialog, index))}
          {Boolean(this.state.showPopover) ?
            this.renderPopover()
            : null}
          {this.state.fullscreenElement || rendered}
        </Box>
      </LocalizationProvider>
    </ThemeProvider>;
  }

  protected abstract renderMain(): ReactElement;

  protected renderLogin() {
    const appConfig = this.getAppConfig();
    return <Login config={appConfig.loginConfig}/>;
  }

  private renderPleaseWait() {
    return <Box
      style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
      <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_SM}}>
        <CircularProgress/>
        <Typography>Please wait...</Typography>
      </Box>
    </Box>;
  }

  private renderFloatingView() {
    return <Draggable>
      <Card style={{
        position: "absolute",
        right: PD_SM,
        top: PD_XXLG,
        width: 320,
        zIndex: 20000,
      }}
            elevation={24}>
        {this.state.showFloatingView}
        <Box style={{
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          height: SZ_HEADER,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        </Box>
      </Card>
    </Draggable>;
  }

  private renderToast() {
    let actionRendered = null;
    if (this.state.showToast.action) {
      actionRendered =
        <Button variant="text" onClick={this.state.showToast.action.onClick}>{this.state.showToast.action.text}</Button>
    }
    return <Snackbar
      open
      autoHideDuration={3000}
      onClose={(event) => {
        this.state.showToast.onClose?.();
        this.setState({
          showToast: null,
        });
      }}
      message={this.state.showToast.text}
      action={actionRendered}
    />;
  }

  private renderFullscreenDialog(dialog: ShowDialog, index: number) {
    let immersive = (dialog.flags & DIALOG_FLAG_SHOW_DIALOG_IMMERSIVE) !== 0;
    let overlay = (dialog.flags & DIALOG_FLAG_SHOW_DIALOG_OVERLAY) !== 0;
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    const content = <Box width="100%" height="100vh" style={{position: "relative"}}>
      {showClose ? this.renderCloseButton() : null}
      <Box style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
      }}>
        {dialog.rendered}
      </Box>
    </Box>

    return <Box style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1300 + index}}>
      {overlay
        ? content
        : <Backdrop style={{background: immersive ? black : white,}} open>
          {content}
        </Backdrop>}
    </Box>;
  }

  private renderDialog(dialog: ShowDialog, index: number) {
    let showClose = (dialog.flags & DIALOG_FLAG_SHOW_CLOSE) !== 0;
    return <Dialog
      TransitionComponent={(dialog.flags & DIALOG_FLAG_ANIM_SLIDE) !== 0 ? DialogTransition : undefined}
      style={{
        zIndex: 1300 + index,
      }}
      scroll="body"
      maxWidth="xl"
      open
      onClose={(event, reason) => {
        if (reason === "backdropClick" && (dialog.flags & DIALOG_FLAG_DISABLE_BACKDROP_CLICK)) {
          return;
        }
        this.hideDialog();
      }}>
      {showClose ? this.renderCloseButton() : null}
      {dialog.rendered}
    </Dialog>;
  }

  protected renderCloseButton(onClose?: () => void) {
    if (!onClose) {
      onClose = () => this.hideDialog();
    }
    return <Box style={{
      display: "flex",
      right: PD_XSM,
      alignItems: "center",
      justifyContent: "flex-end",
      width: SZ_SM,
      height: SZ_SM,
      position: "absolute",
      zIndex: 2000,
    }}>
      <IconButton style={{
        width: SZ_SMSM,
        height: SZ_SMSM,
        background: lightGray,
      }} onClick={onClose}>
        <Close style={{color: "black"}}/>
      </IconButton>
    </Box>;
  }

  private renderPopover() {
    return <Popover
      anchorEl={this.state.showPopover.anchorEl}
      anchorOrigin={this.state.showPopover.anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={this.state.showPopover.transformOrigin || {
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={(event) => {
        this.state.showPopover.onClose?.();
        this.setState({
          showPopover: null,
        });
      }}
      open>
      {this.state.showPopover.rendered}
    </Popover>
  }
}
