import {FooterTabFragment, FooterTabFragmentProps, FooterTabFragmentState} from "../../shared/FooterTabFragment";
import {Grid} from "@mui/material";
import {StyledContainer} from "../../shared/StyledComponents";
import React from "react";
import {PD_LG, PD_XLG} from "../../shared/dimens";
import {Game} from "../types";
import {GameCard} from "../GameCard";
import {GAMES} from "../Games";

export type GamesFragmentProps = FooterTabFragmentProps & {}

export type GamesFragmentState = FooterTabFragmentState & {
}

export class GamesFragment extends FooterTabFragment<GamesFragmentProps, GamesFragmentState> {

  getCustomTitle(): React.ReactElement | null {
    return <img style={{height: 56}} src={"/logotype.png"}/>
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size="lg" style={{padding: PD_XLG}}>
        <Grid container spacing={4}>
          {GAMES.map(game => <Grid item xs={6} sm={4} md={3}>
              <GameCard game={game} onClick={() => this.showGame(game)}/>
            </Grid>
          )}
        </Grid>
      </StyledContainer>;
  }

  private showGame(game: Game) {
    // BaseApp.CONTEXT.showTextDialog("Coming soon", "Stay tuned!", new Action("Okay!", () => BaseApp.CONTEXT.hideDialog()));
    game.show(this.props.path);
  }

}
